<mobi-ui-sidenav-major>
    <ng-template>
        @for (item of sidenavItems(); track item.title) {
            <a
                mobiTealiumTrackLinkOnsite="{{ item.title }}"
                [trackingLinkTarget]="item.routerLink"
                trackingLinkAction="click"
                trackingLinkCategory="OnSiteLink - Left Navigation"
                mobiUiSidenavItemLevel1Major
                class="is-icon-always-visible"
                routerLinkActive="is-active"
                [active]="true"
                [closable]="false"
                [hasChildren]="hasChildren(item)"
                [routerLink]="[item.routerLink]"
                (click)="closeOverlay()"
            >
                <mobi-ui-icon-major [name]="item.icon || 'book_open'" />
                {{ item.title }}
                <!-- lvl 2 sidenav items -->
                <ng-template>
                    <ng-template #backLinkMobile>
                        <a [routerLink]="item.routerLink">
                            <mobi-ui-icon-major name="left" />
                        </a>
                    </ng-template>
                    @for (itemLvl2 of item.children; track itemLvl2.title) {
                        <a
                            mobiTealiumTrackLinkOnsite="{{ itemLvl2.title }}"
                            [trackingLinkTarget]="itemLvl2.routerLink"
                            trackingLinkAction="click"
                            trackingLinkCategory="OnSiteLink - Left Navigation"
                            mobiUiSidenavItemLevel2Major
                            routerLinkActive="is-active"
                            [hasChildren]="hasChildren(itemLvl2)"
                            [routerLink]="[itemLvl2.routerLink]"
                            (click)="closeOverlay()"
                            [mobileBackLinkTemplate]="backLinkMobile"
                        >
                            {{ itemLvl2.title }}
                            <!-- lvl 3 sidenav items -->
                            <ng-template>
                                @for (itemLvl3 of itemLvl2.children; track itemLvl3.title) {
                                    <a
                                        [trackingLinkTarget]="itemLvl3.routerLink"
                                        trackingLinkAction="click"
                                        trackingLinkCategory="OnSiteLink - Left Navigation"
                                        mobiTealiumTrackLinkOnsite="{{ itemLvl3.title }}"
                                        routerLinkActive="is-active"
                                        mobiUiSidenavItemLevel3Major
                                        (click)="closeOverlay()"
                                        [routerLink]="[itemLvl3.routerLink]"
                                    >
                                        {{ itemLvl3.title }}
                                    </a>
                                }
                            </ng-template>
                        </a>
                    }
                </ng-template>
            </a>
        }
    </ng-template>
    <oiv-viewer-give-me-more />
</mobi-ui-sidenav-major>
