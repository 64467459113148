@if (sortedPrompts().length > 0) {
    <p class="mobi-ui-font-bold mobi-ui-font-size-200 mobi-ui-padding-b-sm">
        {{ 'oiv.aiSearch.suggestion' | translate }}
    </p>
    @for (promptObj of sortedPrompts(); track promptObj.index) {
        <div (click)="handlePromptSelected(promptObj.prompt)" class="mobi-ui-card-silent is-clickable">
            <div class="mobi-ui-container mobi-ui-padding-none">
                <div class="mobi-ui-col-5/6 mobi-ui-padding-none mobi-ui-margin-none">
                    <span>{{ promptObj.prompt }}</span>
                </div>
            </div>
        </div>
    }
}
