import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Router } from '@angular/router';

import { MobiUiIconComponent, MobiUiIconsRegistry } from '@mobi/rwc-ui-components-ng-jslib';
import { mobiIconGoToLink } from '@mobi/rwc-utils-icons-jslib';

import { OivCoreSharedFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

export interface SearchResultItem {
    referenceId: string;
    breadcrumb: string;
    title: string;
    description: string;
    urlPath: string;
}
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'oiv-viewer-search-result-item',
    standalone: true,
    imports: [CommonModule, MobiUiIconComponent],
    templateUrl: './search-result-item.component.html',
    styleUrl: './search-result-item.component.scss',
})
export class SearchResultItemComponent {
    #router = inject(Router);

    #coreSharedFacade = inject(OivCoreSharedFacade);

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconGoToLink]);
    }
    resultItem = input<SearchResultItem>({
        referenceId: '',
        breadcrumb: '',
        title: '',
        description: '',
        urlPath: '',
    });

    isAIReference = input<boolean>(false);

    protected highlightSearchContent(description: string): string {
        const regex = /<em>(.*?)<\/em>/g;
        let highlightedDescription = description;
        let match;
        while ((match = regex.exec(description)) !== null) {
            const word = match[1];
            highlightedDescription = highlightedDescription.replace(
                `<em>${word}</em>`,
                `<span class="mobi-ui-font-bold">${word}</span>`,
            );
        }
        return highlightedDescription;
    }

    navigateToResult() {
        this.#coreSharedFacade.closeOverlay();
        this.#router.navigateByUrl(this.resultItem().urlPath);
    }
}
