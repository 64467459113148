import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { LetDirective } from '@ngrx/component';

import { NgIf } from '@angular/common';

import { Layout131Module } from '@mobi/rwc-uxframework-ng';

import {
    MobiUiHeaderComponent,
    MobiUiIconComponent,
    MobiUiPaginatorComponent,
    MobiUiSplitButtonComponent,
    MobiUiSplitButtonGroupComponent,
} from '@mobi/rwc-ui-components-ng-jslib';

import { TealiumTrackInteractionDirective } from '@mobi/tea-tagmanager-ng-jslib';

import { NavigationComponent } from '../../../oiv-viewer/navigation/feature/navigation/navigation.component';
import { SearchBoxComponent } from '../../index-search/ui/search-box/search-box.component';
import { IndexSearchComponent } from '../../index-search/feature/index-search.component';
import { AiSearchComponent } from '../../ai-search/feature/ai-search.component';
import { SearchResultListComponent } from '../../index-search/ui/search-result-list/search-result-list.component';
import { SearchResultItemComponent } from '../../index-search/ui/search-result-item/search-result-item.component';

@NgModule({
    imports: [
        RouterOutlet,
        Layout131Module,
        NavigationComponent,
        LetDirective,
        NgIf,
        MobiUiSplitButtonGroupComponent,
        MobiUiSplitButtonComponent,
        MobiUiHeaderComponent,
        MobiUiIconComponent,
        SearchBoxComponent,
        MobiUiPaginatorComponent,
        IndexSearchComponent,
        AiSearchComponent,
        SearchResultListComponent,
        SearchResultItemComponent,
        TealiumTrackInteractionDirective,
    ],
    exports: [IndexSearchComponent, AiSearchComponent],
})
export class SearchModule {}
