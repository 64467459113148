import { Route, UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';

import { URL_PATH } from '@mobi/oiv-viewer-utils-ng-jslib';

import { ROUTE_PATHS } from '../constant/route-paths';

export const customMatcherChapter: UrlMatcher = (
    segments: UrlSegment[],
    group: UrlSegmentGroup,
    route: Route,
): UrlMatchResult | null => {
    const chaptersSegment = segments[0];
    if (
        chaptersSegment &&
        chaptersSegment.path === URL_PATH.CHAPTERS &&
        !segments.some(segment => segment.path === URL_PATH.TOPICS)
    ) {
        const chapterId = segments
            .slice(1)
            .map(segment => segment.path)
            .join('/');
        const chapterIdSegment: UrlSegment = new UrlSegment(chapterId, {
            id: chapterId,
        });
        return {
            consumed: segments,
            posParams: { [ROUTE_PATHS.ROUTE_PARAM_CHAPTER_ID]: chapterIdSegment },
        };
    }
    return null;
};

export const customMatcherChapterTopics: UrlMatcher = (
    segments: UrlSegment[],
    group: UrlSegmentGroup,
    route: Route,
): UrlMatchResult | null => {
    const chaptersSegment = segments[0];
    const topicsIndex = segments.findIndex(segment => segment.path === URL_PATH.TOPICS);
    if (
        chaptersSegment &&
        chaptersSegment.path === URL_PATH.CHAPTERS &&
        topicsIndex !== -1 &&
        topicsIndex === segments.length - 1
    ) {
        const chapterId = segments
            .slice(1, topicsIndex)
            .map(segment => segment.path)
            .join('/');
        const chapterIdSegment: UrlSegment = new UrlSegment(chapterId, {
            id: chapterId,
        });
        return {
            consumed: segments,
            posParams: { [ROUTE_PATHS.ROUTE_PARAM_CHAPTER_ID]: chapterIdSegment },
        };
    }
    return null;
};

export const customMatcherChapterTopicsId: UrlMatcher = (
    segments: UrlSegment[],
    group: UrlSegmentGroup,
    route: Route,
): UrlMatchResult | null => {
    const chaptersSegment = segments[0];
    const topicsIndex = segments.findIndex(segment => segment.path === URL_PATH.TOPICS);
    if (
        chaptersSegment &&
        chaptersSegment.path === URL_PATH.CHAPTERS &&
        topicsIndex !== -1 &&
        segments.length > topicsIndex + 1
    ) {
        const chapterId = segments
            .slice(1, topicsIndex)
            .map(segment => segment.path)
            .join('/');
        const chapterIdSegment: UrlSegment = new UrlSegment(chapterId, {
            id: chapterId,
        });
        const topicId = segments
            .slice(topicsIndex + 1)
            .map(segment => segment.path)
            .join('/');
        const topicIdSegment: UrlSegment = new UrlSegment(topicId, {
            id: topicId,
        });
        return {
            consumed: segments,
            posParams: {
                [ROUTE_PATHS.ROUTE_PARAM_CHAPTER_ID]: chapterIdSegment,
                [ROUTE_PATHS.ROUTE_PARAM_TOPIC_ID]: topicIdSegment,
            },
        };
    }
    return null;
};
