import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

@Component({
    selector: 'oiv-viewer-separator',
    standalone: true,
    imports: [CommonModule, TranslatePipe],
    templateUrl: './separator.component.html',
    styleUrl: './separator.component.scss',
})
export class SeparatorComponent {
    dateTime = input<string>();
    text = input.required<string>();
    isBold = input.required<boolean>();
}
