<div class="mobi-ui-animation-appear-init mobi-ui-animation-appear-render mobi-ui-card-silent mobi-ui-relative">
    <div class="gptlib-message-bubble mobi-ui-slot-major mobi-ui-ink-default is-top-left-inner">
        <mobi-ui-icon-major name="message" size="md" />
    </div>
    @if (!message().content && message().status === 'generating') {
        <div class="mobi-ui-padding-t-sm" [libGPTCursor]="true"></div>
    }
    <lib-gpt-message
        class="mobi-ui-padding-b-xxs mobi-ui-margin-t-md"
        [message]="message()"
        (onNavigate)="closeOverlay()"
        libGPTLinkToRouterLink
    ></lib-gpt-message>
    @if (references().length) {
        <div class="mobi-ui-font-size-50 mobi-ui-font-bold mobi-ui-margin-t-xxl">
            {{ 'oiv.aiSearch.reference' | translate }}
        </div>
        @for (reference of references(); track reference.title) {
            <div class="mobi-ui-separator-minor"></div>
            <oiv-viewer-search-result-item
                [resultItem]="reference"
                [isAIReference]="true"
            ></oiv-viewer-search-result-item>
        }
    }
    @if (message().status !== 'generating') {
        <div class="mobi-ui-separator-minor mobi-ui-margin-b-none"></div>
        <div class="oiv-message-actions mobi-ui-padding-xxs mobi-ui-flex">
            <mobi-ui-icon-major
                size="md"
                name="hand_thumbs_up"
                [ngClass]="{ selected: message().type === FEEDBACK_TYPE_POSITIVE }"
                class="positive-feedback"
                [mobiUiOverlay]="feedbackTemplate"
                (click)="setFeedbackType(FEEDBACK_TYPE_POSITIVE)"
            ></mobi-ui-icon-major>
            <mobi-ui-icon-major
                size="md"
                name="hand_thumbs_down"
                [ngClass]="{ selected: message().type === FEEDBACK_TYPE_NEGATIVE }"
                class="negative-feedback"
                [mobiUiOverlay]="feedbackTemplate"
                (click)="setFeedbackType(FEEDBACK_TYPE_NEGATIVE)"
            ></mobi-ui-icon-major>
            <mobi-ui-icon-major
                size="md"
                name="copy"
                (click)="copyToClipboard()"
                [mobiUiPopover]="copyConfirmation"
            ></mobi-ui-icon-major>
        </div>
    }
</div>
@if (message().status === 'failed') {
    <mobi-ui-icon-major name="error"></mobi-ui-icon-major>
    <div class="mobi-ui-text-block-content-major mobi-ui-font-size-100">
        An error occurred while processing the request. Please try again.
    </div>
}
<ng-template #copyConfirmation>
    <mobi-ui-popover-major>
        <span [innerHTML]="'oiv.message.copy' | translate"></span>
    </mobi-ui-popover-major>
</ng-template>

<ng-template #feedbackTemplate let-overlay>
    <mobi-ui-overlay-major
        class="oiv-overlay-custom is-full-size-content"
        [size]="'md'"
        [overlay]="overlay"
        [showCloseOverlayButton]="false"
    >
        <div
            class="oiv-custom-x-button mobi-ui-padding-r-md mobi-ui-padding-t-md mobi-ui-text-inked"
            (click)="overlay.close()"
        >
            <mobi-ui-icon-major name="the_x"></mobi-ui-icon-major>
        </div>
        <oiv-viewer-feedback
            [categories]="categories()"
            [feedbackType]="feedbackType"
            (feedbackSent)="handleSendFeedback($event, message().id, overlay)"
        ></oiv-viewer-feedback>
    </mobi-ui-overlay-major>
</ng-template>
