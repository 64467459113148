import { computed } from '@angular/core';
import { patchState, signalStoreFeature, withComputed, withMethods, withState } from '@ngrx/signals';

import { Feedback } from '../model/message.model';

export type FeedbackResponseStatus = {
    feedback?: Feedback;
    isPending?: boolean;
};

export type FeedbackResponseState = {
    feedbackResponseStatus: FeedbackResponseStatus;
};

export function withFeedbackResponseStatus() {
    return signalStoreFeature(
        withState<FeedbackResponseState>({ feedbackResponseStatus: {} }),
        withComputed(({ feedbackResponseStatus }) => ({
            feedback: computed(() => feedbackResponseStatus().feedback || null),
        })),
        withMethods(store => ({
            setFeedbackFulfilled(feedback: Feedback) {
                patchState(store, {
                    feedbackResponseStatus: { feedback, isPending: false },
                });
            },
            setFeedbackPending(value: boolean) {
                patchState(store, {
                    feedbackResponseStatus: { isPending: value },
                });
            },
        })),
    );
}
