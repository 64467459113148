<div class="mobi-ui-padding-sm mobi-ui-margin-none mobi-ui-padding-md">
    <p class="mobi-ui-font-bold mobi-ui-font-size-200 mobi-ui-padding-y-xxs">
        {{ 'oiv.not-found.more-possibilities' | translate }}
    </p>
    <ul class="mobi-ui-list-minor">
        @for (item of items; track item.text) {
            <li>
                <a class="mobi-ui-padding-y-xxs mobi-ui-ink-black" [href]="item.link | translate">
                    @if (item.icon === 'face') {
                        <mobi-ui-icon-major
                            class="mobi-ui-ink-default"
                            [isInked]="true"
                            name="face"
                        ></mobi-ui-icon-major>
                    } @else {
                        <mobi-ui-icon-major
                            class="mobi-ui-ink-default"
                            [isInked]="true"
                            name="application_window"
                        ></mobi-ui-icon-major>
                    }
                    <span class="mobi-ui-font-size-250">{{ item.text | translate }}</span>
                    <mobi-ui-icon-major name="go_to_link"></mobi-ui-icon-major>
                </a>
            </li>
        }
        <li></li>
    </ul>
</div>
