import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import {
    MobiUiButtonBarComponent,
    MobiUiIconComponent,
    MobiUiIconsRegistry,
    MobiUiInputComponent,
    MobiUiMessageItemComponent,
    MobiUiMessagesComponent,
    MobiUiOverlayComponent,
} from '@mobi/rwc-ui-components-ng-jslib';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { mobiIconPaperPlane, mobiIconTheX } from '@mobi/rwc-utils-icons-jslib';

import { ParagraphComponent } from '../../../../share/components/paragraph/paragraph.component';
import { FEEDBACK_TYPE_NEGATIVE } from '../../../../util/constant/common';

@Component({
    selector: 'oiv-viewer-feedback',
    standalone: true,
    imports: [
        CommonModule,
        MobiUiOverlayComponent,
        TranslatePipe,
        MobiUiIconComponent,
        ParagraphComponent,
        MobiUiButtonBarComponent,
        MobiUiInputComponent,
        FormsModule,
        MobiUiMessagesComponent,
        MobiUiMessageItemComponent,
    ],
    templateUrl: './feedback.component.html',
    styleUrl: './feedback.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent {
    protected readonly FEEDBACK_TYPE_NEGATIVE = FEEDBACK_TYPE_NEGATIVE;

    categories = input<string[]>([]);

    feedbackType = input<string>('');

    feedbackSent = output<{ category: string; feedBackContent: string }>();

    activeButtonKey: string | null = null;

    contentInputValue = '';

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconPaperPlane, mobiIconTheX]);
    }

    setActiveButton(key: string): void {
        this.activeButtonKey = key;
    }

    sendFeedbackData(): void {
        const category = this.categories().find(category => category === this.activeButtonKey) || '';
        this.feedbackSent.emit({ category, feedBackContent: this.contentInputValue });
        this.activeButtonKey = null;
        this.contentInputValue = '';
    }
}
