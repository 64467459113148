import {
    ChangeDetectionStrategy,
    Component,
    computed,
    HostListener,
    inject,
    input,
    NgZone,
    OnInit,
    output,
    signal,
    ViewChild,
} from '@angular/core';

import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';

import {
    MobiUiFormFieldComponent,
    MobiUiFormMessageComponent,
    MobiUiIconComponent,
    MobiUiIconsRegistry,
    MobiUiLoadingIndicatorComponent,
    MobiUiTextareaComponent,
} from '@mobi/rwc-ui-components-ng-jslib';
import { mobiIconItemNew, mobiIconPaperPlane, mobiIconTheX } from '@mobi/rwc-utils-icons-jslib';
import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { isHTMLElementInViewport } from '../../../../util/dom';

@Component({
    selector: 'oiv-console',
    standalone: true,
    imports: [
        MobiUiTextareaComponent,
        MobiUiIconComponent,
        MobiUiLoadingIndicatorComponent,
        CommonModule,
        TranslatePipe,
        ReactiveFormsModule,
        MobiUiFormFieldComponent,
        MobiUiFormMessageComponent,
    ],
    templateUrl: './console.component.html',
    styleUrl: './console.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsoleComponent implements OnInit {
    #ngZone = inject(NgZone);

    @ViewChild('textareaComponent', { static: true })
    textarea?: MobiUiTextareaComponent;

    isGenerating = input.required<boolean>();
    isIndexAvailable = input.required<boolean>();
    isFollowUpQuestion = input.required<boolean>();
    hideCreateConversationBtn = input<boolean>(false);
    currentSelectedFilterItem = input<string>('');

    isCreateNewConversation = output<boolean>();
    messageSent = output<string>();
    cancel = output<void>();

    consoleStyleObject = signal({});
    autosize = signal(false);
    haveMessage = computed(() => this.textarea?.value());

    resizeObserver!: ResizeObserver;

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconPaperPlane, mobiIconTheX, mobiIconItemNew]);
    }

    @HostListener('document:keydown.enter', ['$event'])
    handleEnterKey(event: KeyboardEvent): void {
        if (this.textarea?.value().trim() === '') {
            return;
        }
        event.preventDefault();
        this.sendMessage();
    }

    @HostListener('document:keydown.escape', ['$event'])
    handleEscapeKey(event: KeyboardEvent): void {
        event.preventDefault();
        this.stopGenerating();
    }

    @HostListener('window:scroll', [])
    checkScrollPosition() {
        this.addConsoleBottomToPreventFooterOverlap();
    }

    ngOnInit(): void {
        this.#ngZone.runOutsideAngular(() => {
            const aiSearchNode = document.getElementById('ai-search');
            if (aiSearchNode) {
                this.resizeObserver = new ResizeObserver(entries => {
                    this.updateStickyConsoleWidth();
                    this.addConsoleBottomToPreventFooterOverlap();
                });
                this.resizeObserver.observe(aiSearchNode);
            }
        });
    }

    sendMessage(): void {
        if (this.currentSelectedFilterItem()) {
            // select filter
            const messageText = this.textarea?.value().trim() ?? '';
            this.messageSent.emit(messageText);
            // Clear the textarea after sending the message
            this.textarea?.writeValue('');
        } else {
            // not select filter
            // Should emit an empty message to show error on the filter
            this.messageSent.emit('');
        }
    }

    stopGenerating(): void {
        if (!this.isGenerating()) {
            return;
        }
        this.cancel.emit();
    }

    updateStickyConsoleWidth() {
        this.consoleStyleObject.update(value => {
            return {
                ...value,
                ...{
                    width: `calc(${document.getElementById('ai-search')?.offsetWidth}px)`,
                },
            };
        });
    }

    addConsoleBottomToPreventFooterOverlap() {
        const paddingFooter = isHTMLElementInViewport(document.getElementById('oiv-footer-wrapper')) ? '80px' : '0px';
        this.consoleStyleObject.update(value => {
            return {
                ...value,
                ...{ ...this.consoleStyleObject, ...{ bottom: paddingFooter } },
            };
        });
    }

    createNewConversation() {
        this.stopGenerating();
        this.isCreateNewConversation.emit(true);
    }

    focus() {
        if (!this.autosize()) {
            this.autosize.set(true);
        }
    }
}
