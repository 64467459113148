<div class="mobi-ui-width-full" (click)="navigateToResult()">
    <div class="mobi-ui-space-between">
        <p
            class="mobi-ui-font-size-50 mobi-ui-margin-b-xxs mobi-ui-font-italic"
            [innerHTML]="resultItem().breadcrumb"
        ></p>
        <a class="mobi-navigation-button">
            <mobi-ui-icon-major name="go_to_link" />
        </a>
    </div>
    <h5
        [ngClass]="{
            'mobi-ui-text-inked mobi-ui-font-light': !isAIReference(),
            'mobi-ui-font-bold': isAIReference(),
        }"
        class="mobi-ui-margin-b-xxxs mobi-ui-font-tisa mobi-ui-padding-none mobi-ui-font-size-100"
        [innerHTML]="highlightSearchContent(resultItem().title)"
    ></h5>
    <p
        class="mobi-ui-font-size-md mobi-ui-margin-b-md"
        [innerHTML]="highlightSearchContent(resultItem().description)"
    ></p>
</div>
