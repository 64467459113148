import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { Observable, of } from 'rxjs';

import { LoadingState } from '@mobi/rwc-uxframework-ng';

import { SearchParam } from '../../model/search-param';
import { SearchResult } from '../../model/search-model';
import { AlgoliaService } from '../service/algolia.service';

export interface IndexSearchState {
    searchResult: SearchResult;
    page: number;
    text: string;
    loadingSearch: LoadingState;
    algoliaUserToken?: string;
}
const DEFAULT_STATE: IndexSearchState = {
    searchResult: { hits: [], nbHits: 0, nbPages: 0 },
    page: 0,
    text: '',
    loadingSearch: LoadingState.READY,
};

@Injectable()
export class IndexSearchComponentStore extends ComponentStore<IndexSearchState> {
    #algoliaService = inject(AlgoliaService);

    constructor() {
        super(DEFAULT_STATE);
    }

    readonly loadingSearch$ = this.select(state => state.loadingSearch);
    readonly searchResult$ = this.select(state => state.searchResult);
    readonly page$ = this.select(state => state.page);
    readonly text$ = this.select(state => state.text);

    readonly setText = this.updater((state, text: string) => ({ ...state, text }));
    readonly setPage = this.updater((state, page: number) => ({ ...state, page }));

    readonly search = this.effect((searchParam$: Observable<SearchParam>) =>
        searchParam$.pipe(
            tap(searchParam => {
                this.setText(searchParam.text);
                this.setPage(searchParam.page);
                this.patchState({ loadingSearch: LoadingState.LOADING });
            }),
            switchMap(searchParam =>
                this.#algoliaService
                    .performSearch(searchParam.text, this.#algoliaService.createRequestOption(searchParam))
                    .pipe(
                        tap(searchResult => {
                            if (searchParam.page + 1 > searchResult.nbPages) {
                                searchResult.nbHits = 0;
                            }
                            this.patchState({ searchResult, loadingSearch: LoadingState.READY });
                        }),
                        catchError(error => {
                            this.patchState({ loadingSearch: LoadingState.ERROR });
                            return of({ hits: [], nbHits: 0, nbPages: 0 });
                        }),
                    ),
            ),
        ),
    );
}
