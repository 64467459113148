import { Component } from '@angular/core';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { AiFacade } from '../../oiv-search/ai-search/data/facade/ai-facade';
import { AiStore } from '../../oiv-search/ai-search/data/store/ai.store';

@Component({
    selector: 'oiv-viewer-no-permission',
    standalone: true,
    imports: [TranslatePipe],
    templateUrl: './no-permission.component.html',
    styleUrl: './no-permission.component.scss',
    providers: [AiFacade, AiStore],
})
export class NoPermissionComponent {}
