import { Component, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MobiUiIconComponent, MobiUiIconsRegistry } from '@mobi/rwc-ui-components-ng-jslib';
import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';
import { mobiIconError } from '@mobi/rwc-utils-icons-jslib';

@Component({
    selector: 'oiv-viewer-error-message',
    standalone: true,
    imports: [CommonModule, MobiUiIconComponent, TranslatePipe],
    templateUrl: './error-message.component.html',
    styleUrl: './error-message.component.scss',
})
export class ErrorMessageComponent {
    messageTitle = input.required<string>();
    messageDescription = input.required<string>();
    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconError]);
    }
}
