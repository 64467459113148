import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule, RouterOutlet, Routes } from '@angular/router';

import { LetDirective, PushPipe } from '@ngrx/component';

import { AsyncPipe, NgIf, NgStyle } from '@angular/common';

import { Layout131Module } from '@mobi/rwc-uxframework-ng';

import {
    MobiUiIconComponent,
    MobiUiSplitButtonComponent,
    MobiUiSplitButtonGroupComponent,
} from '@mobi/rwc-ui-components-ng-jslib';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { OivErrorHandler, PAGE_URL_PATH } from '@mobi/oiv-viewer-utils-ng-jslib';

import { NavigationComponent } from '../../navigation/feature/navigation/navigation.component';

import {
    customMatcherChapter,
    customMatcherChapterTopics,
    customMatcherChapterTopicsId,
} from '../../../util/route-utils/custom-matcher';

import { SharedModule } from '../../../share/share.module';
import { SearchModule } from '../../../oiv-search/core/search-dashboard/search.module';

import { CoverSheetComponent } from '../../navigation/ui/cover-sheet/cover-sheet.component';

import { AlertComponent } from '../../alert/feature/alert.component';

import { ChapterPageWithTopicComponentComponent } from '../../chapter-page/feature/chapter-page-with-topic-component/chapter-page-with-topic-component.component';
import { ChapterPageNoTopicComponentComponent } from '../../chapter-page/feature/chapter-page-no-topic-component/chapter-page-no-topic-component.component';
import { ChapterPageComponent } from '../../chapter-page/feature/chapter-page.component';

import { AiStore } from '../../../oiv-search/ai-search/data/store/ai.store';

import { AiFacade } from '../../../oiv-search/ai-search/data/facade/ai-facade';

import { ChapterTopicsPageComponent } from '../../chapter-topics-page/feature/chapter-topics-page.component';

import { MainLayoutComponent } from './main-layout.component';

const routes: Routes = [
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            {
                path: PAGE_URL_PATH.HOMEPAGE,
                pathMatch: 'full',
                loadComponent: () => import('../../homepage/feature/homepage.component').then(m => m.HomepageComponent),
            },
            {
                pathMatch: 'full',
                matcher: customMatcherChapterTopicsId,
                loadComponent: () =>
                    import(
                        '../../chapter-page/feature/chapter-page-with-topic-component/chapter-page-with-topic-component.component'
                    ).then(m => m.ChapterPageWithTopicComponentComponent),
            },
            {
                pathMatch: 'full',
                matcher: customMatcherChapterTopics,
                loadComponent: () =>
                    import('../../chapter-topics-page/feature/chapter-topics-page.component').then(
                        m => m.ChapterTopicsPageComponent,
                    ),
            },
            {
                pathMatch: 'full',
                matcher: customMatcherChapter,
                loadComponent: () =>
                    import(
                        '../../chapter-page/feature/chapter-page-no-topic-component/chapter-page-no-topic-component.component'
                    ).then(m => m.ChapterPageNoTopicComponentComponent),
            },
            {
                path: '',
                redirectTo: PAGE_URL_PATH.HOMEPAGE,
                pathMatch: 'full',
            },
            {
                path: '**',
                redirectTo: PAGE_URL_PATH.HOMEPAGE,
                pathMatch: 'full',
            },
        ],
    },
];

@NgModule({
    declarations: [MainLayoutComponent],
    imports: [
        RouterModule.forChild(routes),
        RouterOutlet,
        Layout131Module,
        NavigationComponent,
        LetDirective,
        NgIf,
        SharedModule,
        SearchModule,
        PushPipe,
        AsyncPipe,
        MobiUiSplitButtonComponent,
        MobiUiIconComponent,
        CoverSheetComponent,
        MobiUiSplitButtonGroupComponent,
        TranslatePipe,
        AlertComponent,
        NgStyle,
        ChapterPageWithTopicComponentComponent,
        ChapterPageNoTopicComponentComponent,
        ChapterPageComponent,
        ChapterTopicsPageComponent,
    ],
    providers: [{ provide: ErrorHandler, useClass: OivErrorHandler }, AiStore, AiFacade],
    exports: [MainLayoutComponent, RouterModule],
})
export class MainLayoutModule {}
