import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { AiFacade } from '../../oiv-search/ai-search/data/facade/ai-facade';
import { AiStore } from '../../oiv-search/ai-search/data/store/ai.store';

@Component({
    standalone: true,
    selector: 'oiv-viewer-not-available',
    templateUrl: './not-available.component.html',
    styleUrl: './not-available.component.scss',
    imports: [TranslatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AiFacade, AiStore],
})
export class NotAvailableComponent {}
