<div class="mobi-ui-section-minor mobi-ui-margin-l-lg">
    <h3 class="mobi-ui-ink-default">{{ 'oiv.feedback.title' | translate }}</h3>
    <p>{{ 'oiv.feedback.introduction' | translate }}</p>
</div>
<div class="mobi-ui-margin-x-xl">
    @if (feedbackType() === FEEDBACK_TYPE_NEGATIVE) {
        <div class="mobi-ui-flow mobi-ui-margin-t-lg">
            @for (category of categories(); track category) {
                <button
                    type="button"
                    class="mobi-ui-btn-stroked-minor mobi-ui-padding-xxs is-squared oiv-custom-button is-small mobi-ui-font-size-50"
                    [ngClass]="{ active: activeButtonKey === category }"
                    (click)="setActiveButton(category)"
                >
                    {{ 'oiv.feedback.category.' + category.toLocaleLowerCase() | translate }}
                </button>
            }
        </div>
    }
    <mobi-ui-input-major
        class="mobi-ui-margin-t-lg"
        [(ngModel)]="contentInputValue"
        placeholder="{{ 'oiv.feedback.input.placeholder' | translate }}"
    ></mobi-ui-input-major>
    <div class="mobi-ui-padding-b-lg oiv-custom-right-side mobi-ui-margin-t-lg">
        <button type="button" class="mobi-ui-btn-icon-major" (click)="sendFeedbackData()">
            <mobi-ui-icon-major name="paper_plane"></mobi-ui-icon-major>
        </button>
    </div>
</div>
