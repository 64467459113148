import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { oivViewerFeature } from './store/oiv-viewer.reducer';
import { OivViewerEffects } from './store/oiv-viewer-effects.service';

export const provideOivViewer = [
    provideState({
        name: oivViewerFeature.name,
        reducer: oivViewerFeature.reducer,
    }),
    provideEffects(OivViewerEffects),
];
