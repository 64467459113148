<mobi-ui-messages-major class="mobi-ui-flex">
    @for (message of notifications(); track message) {
        <mobi-ui-message-item-major
            [level]="message.level"
            [messageCloseTimeout]="message.messageCloseTimeout"
            (messageClose)="removeMessage(message)"
        >
            {{ message.text }}
        </mobi-ui-message-item-major>
    }
</mobi-ui-messages-major>
