import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';

import {
    MobiUiIconComponent,
    MobiUiIconsRegistry,
    MobiUiMessageItemComponent,
    MobiUiMessagesComponent,
    MobiUiOverlayComponent,
    MobiUiOverlayDirective,
    MobiUiPopoverComponent,
    MobiUiPopoverDirective,
} from '@mobi/rwc-ui-components-ng-jslib';
import {
    mobiIconCopy,
    mobiIconError,
    mobiIconHandThumbsDown,
    mobiIconHandThumbsUp,
    mobiIconMessage,
    mobiIconReload,
    mobiIconSuccess,
    mobiIconTheX,
    mobiIconWarning,
} from '@mobi/rwc-utils-icons-jslib';
import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { GPTCursorDirective, GPTLinkToRouterLinkDirective, GPTMessageComponent } from '@mobi/gpt-chat-ng-jslib';

import { OivCoreSharedFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

import { Message } from '../../data/model/message.model';
import { FeedbackComponent } from '../feedback/feedback.component';

import { FEEDBACK_TYPE_NEGATIVE, FEEDBACK_TYPE_POSITIVE } from '../../../../util/constant/common';
import {
    SearchResultItem,
    SearchResultItemComponent,
} from '../../../index-search/ui/search-result-item/search-result-item.component';

@Component({
    selector: 'oiv-assistant-message',
    standalone: true,
    imports: [
        CommonModule,
        MobiUiIconComponent,
        TranslatePipe,
        GPTMessageComponent,
        GPTCursorDirective,
        MobiUiPopoverDirective,
        MobiUiPopoverComponent,
        FeedbackComponent,
        MobiUiOverlayDirective,
        MobiUiOverlayComponent,
        MobiUiMessageItemComponent,
        MobiUiMessagesComponent,
        SearchResultItemComponent,
        GPTLinkToRouterLinkDirective,
    ],
    templateUrl: './assistant-message.component.html',
    styleUrl: './assistant-message.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssistantMessageComponent {
    #coreSharedFacade = inject(OivCoreSharedFacade);

    categories = input<string[]>([]);
    message = input.required<Message>();
    references = input<SearchResultItem[]>([]);
    feedbackSent = output();

    feedbackType = '';

    protected readonly FEEDBACK_TYPE_POSITIVE = FEEDBACK_TYPE_POSITIVE;
    protected readonly FEEDBACK_TYPE_NEGATIVE = FEEDBACK_TYPE_NEGATIVE;

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([
            mobiIconHandThumbsUp,
            mobiIconHandThumbsDown,
            mobiIconCopy,
            mobiIconReload,
            mobiIconTheX,
            mobiIconError,
            mobiIconWarning,
            mobiIconMessage,
            mobiIconSuccess,
        ]);
    }

    copyToClipboard(): void {
        let content = this.message().content!;
        const regex = /\s*\[\[([^\]]+)\]\]\((\/[^)]+)\)/g; // this regex detects the inline-reference [[]]() in the message content
        content = content.replace(regex, '').replace(/\s\s+/g, ' ').trim();
        navigator.clipboard.writeText(content);
        this.#timeout();
    }

    #timeout() {
        setTimeout(() => document.body.click(), 1000);
    }

    setFeedbackType(type: string): void {
        this.feedbackType = type;
    }

    handleSendFeedback(feedback: any, messageId: string, overlay: any): void {
        feedback.type = this.feedbackType;
        feedback.messageId = messageId;
        this.feedbackSent.emit(feedback);
        overlay.close();
    }

    closeOverlay() {
        this.#coreSharedFacade.closeOverlay();
    }
}
