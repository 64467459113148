import { Component, computed, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { PromptDTO } from '../../../../oiv-viewer/core/data/service/oiv-viewer.service';

@Component({
    selector: 'oiv-viewer-prompt',
    standalone: true,
    imports: [CommonModule, TranslatePipe],
    templateUrl: './prompt.component.html',
    styleUrl: './prompt.component.scss',
})
export class PromptComponent {
    prompts = input.required<PromptDTO[]>();

    selectPrompt = output<string>();

    sortedPrompts = computed(() => this.prompts().sort((a, b) => a.index - b.index));

    handlePromptSelected(prompt: string) {
        this.selectPrompt.emit(prompt);
    }
}
