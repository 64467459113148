import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PushPipe } from '@ngrx/component';

import { OverlayComponent } from './components/overlay/overlay.component';

@NgModule({
    imports: [CommonModule, PushPipe, OverlayComponent],
    exports: [OverlayComponent],
})
export class SharedModule {}
