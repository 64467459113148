import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { OivCoreSharedFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

import { SidenavComponent } from '../../ui/sidenav/sidenav.component';
import { NavigationFacade } from '../../data/navigation-facade';

@Component({
    standalone: true,
    selector: 'oiv-viewer-navigation',
    templateUrl: 'navigation.component.html',
    imports: [SidenavComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
    protected navigationFacade = inject(NavigationFacade);
    #coreSharedFacade = inject(OivCoreSharedFacade);

    sidenavItems = this.navigationFacade.sidenav;

    closeOverlay() {
        this.#coreSharedFacade.closeOverlay();
    }
}
