import { inject, Injectable, Signal } from '@angular/core';

import { v4 as uuidv4 } from 'uuid';

import { DateTime } from 'luxon';

import { OivError } from '@mobi/oiv-viewer-utils-ng-jslib';

import { Conversation, Feedback, Message, MessageRole } from '../model/message.model';
import { AiStore } from '../store/ai.store';
import { PromptDTO } from '../../../../oiv-viewer/core/data/service/oiv-viewer.service';
import { AssetInfo } from '../../../../share/model/atomic-model';
import { CONVERSATION_NOT_FOUND } from '../../../../util/constant/error-message';
import { MessageDTO, MessageRequestDTO } from '../service/question-and-answer.service';

@Injectable()
export class AiFacade {
    #aiStore = inject(AiStore);

    initializeAiStore(assetInfo: AssetInfo) {
        this.#aiStore.initialize(assetInfo);
    }

    get conversations(): Signal<Conversation[]> {
        return this.#aiStore.conversations;
    }

    get isGenerating(): Signal<boolean> {
        return this.#aiStore.isGenerating;
    }

    get isDisappearCreateNewConversationFunction(): Signal<boolean> {
        return this.#aiStore.isDisappearCreateNewConversationFunction;
    }

    get isDisplaySuggestion(): Signal<boolean> {
        return this.#aiStore.isDisplaySuggestion;
    }

    get currentConversationId(): Signal<string> {
        return this.#aiStore.currentConversationId;
    }

    get isIndexAvailable(): Signal<boolean> {
        return this.#aiStore.isIndexAvailable;
    }

    get isPending(): Signal<boolean> {
        return this.#aiStore.isPending;
    }

    get isFollowUpQuestion(): Signal<boolean> {
        return this.#aiStore.isFollowUpQuestion;
    }

    get currentSelectedFilterItem(): Signal<string> {
        return this.#aiStore.currentFilterItem;
    }

    set currentSelectedFilterItem(filterItem: string) {
        this.#aiStore.setCurrentSelectedFilterItem(filterItem);
    }

    get prompts(): Signal<PromptDTO[]> {
        return this.#aiStore.prompts;
    }

    sendMessageSubmissionRequested(
        currentConversationId: string,
        productArt: number,
        documentArt: number,
        messageText: string,
        filter: string,
        assetType: string,
        contentVersion: string,
    ) {
        const currentConversation = this.#aiStore
            .conversations()
            .find((item: Conversation) => item.id === currentConversationId);
        if (!currentConversation) {
            throw new OivError(CONVERSATION_NOT_FOUND);
        }
        // Handle messages in the store
        this.#handleMessagesInStore(currentConversation, messageText);
        // Handle messages to send to AI server
        this.#sendMessagesToAiServer(currentConversation, productArt, documentArt, filter, assetType, contentVersion);
    }

    sendFeedback(messageId: string, feedback: Feedback) {
        this.#aiStore.sendFeedback({ messageId, feedback: feedback });
    }

    stopGeneratingRequested() {
        this.#aiStore.stopGeneratingRequested();
    }

    loadPrompts() {
        this.#aiStore.loadPrompts();
    }

    addTmpConversation() {
        this.#aiStore.setCurrentSelectedFilterItem('');
        this.#aiStore.addTmpConversation();
    }

    clearAll() {
        this.#aiStore.cleanUp();
    }

    #handleMessagesInStore(currentConversation: Conversation, messageText: string) {
        const message: Message = {
            id: uuidv4(),
            content: messageText,
            creationTimestamp: DateTime.now(),
            role: 'user',
            status: 'completed',
        };
        currentConversation.messages.push(message);
        this.#aiStore.updateConversation(currentConversation);
    }

    #sendMessagesToAiServer(
        currentConversation: Conversation,
        productArt: number,
        documentArt: number,
        filter: string,
        assetType: string,
        contentVersion: string,
    ) {
        // get all existing messages in current conversation
        const existingMessages: MessageDTO[] = currentConversation.messages
            .map(message => ({
                content: message.content ?? '',
                role: message.role as MessageRole,
            }))
            .filter(message => message.content !== undefined);

        const request: MessageRequestDTO = {
            conversation_id: '',
            text_reference: this.#aiStore.textReference(),
            fdt_dokumentArt: documentArt.toString(),
            fdt_produktArt: productArt.toString(),
            language: this.#aiStore.language(),
            asset_type: assetType,
            content_version: contentVersion,
            messages: existingMessages,
        };
        // if filter is 'all-topics',
        // remove filter attribute because question answering service treat it as 'all-filters'
        if (filter !== 'all-topics') {
            request.filter = filter;
        }

        if (currentConversation.combinedId) {
            this.#aiStore.sendMessage({
                requestPayload: request,
                currentConversation,
                needToCreateConversation: false,
            });
        } else {
            this.#aiStore.sendMessage({
                requestPayload: request,
                currentConversation,
                needToCreateConversation: true,
            });
        }
    }
}
