import { Component, inject, input, output, viewChild } from '@angular/core';

import {
    MobiUiDropdownComponent,
    MobiUiDropdownOptionComponent,
    MobiUiIconComponent,
    MobiUiIconsRegistry,
} from '@mobi/rwc-ui-components-ng-jslib';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { mobiIconPlaceholder } from '@mobi/rwc-utils-icons-jslib';

@Component({
    selector: 'oiv-viewer-ai-filter',
    standalone: true,
    templateUrl: './ai-filter.component.html',
    styleUrl: './ai-filter.component.scss',
    imports: [MobiUiDropdownComponent, MobiUiDropdownOptionComponent, TranslatePipe, MobiUiIconComponent],
})
export class AiFilterComponent {
    dropdownComponent = viewChild.required(MobiUiDropdownComponent);

    chosenItem = input<string>();
    isMissingFilter = input<boolean>();
    chapterTitles = input.required<string[]>();

    dropdownOpen = output<boolean>();
    selectedItem = output<string>();

    value: string | null | undefined = '';

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconPlaceholder]);
    }

    handleValueChange(value: any) {
        this.value = value;
        if (value !== '') {
            this.selectedItem.emit(value);
        }
    }

    dropdownClick() {
        this.dropdownOpen.emit(this.dropdownComponent().showOptions());
    }
}
