<div class="mobi-ui-margin-b-sm">
    @if (chosenItem()) {
        <div class="mobi-ui-card-silent">
            <div class="mobi-ui-text-block-major">
                <mobi-ui-icon-major name="placeholder"></mobi-ui-icon-major>
                <div class="mobi-ui-text-block-content-major">
                    <span data-cy="filterContent" class="mobi-ui-text-block-title-major">
                        {{ 'oiv.aiSearch.filter.chosenTopic' | translate }}</span
                    >
                    @if (chosenItem() === 'all-topics') {
                        {{ 'oiv.aiSearch.filter.selectAllTopics' | translate }}
                    } @else {
                        {{ chosenItem() }}
                    }
                </div>
            </div>
        </div>
    } @else {
        <mobi-ui-dropdown-major
            data-cy="filterDropdown"
            [emptyOptionLabel]="'oiv.aiSearch.filter.selectTopic' | translate"
            [value]="value"
            (valueChange)="handleValueChange($event)"
            [class.outline]="isMissingFilter()"
            (click)="dropdownClick()"
        >
            <mobi-ui-dropdown-option-major value="all-topics">{{
                'oiv.aiSearch.filter.selectAllTopics' | translate
            }}</mobi-ui-dropdown-option-major>
            @for (title of chapterTitles(); track title) {
                <mobi-ui-dropdown-option-major value="{{ title }}">{{ title }}</mobi-ui-dropdown-option-major>
            }
        </mobi-ui-dropdown-major>
    }
</div>
