<div class="mobi-ui-margin-t-xl">
    <oiv-viewer-separator
        [dateTime]="conversation().createdDate?.toLocaleString(DateTime.TIME_24_SIMPLE)"
        [text]="'oiv.aiSearch.newConversationSeparator' | translate"
        [isBold]="true"
    />

    <oiv-viewer-ai-filter
        [chapterTitles]="chapterTitles()"
        [isMissingFilter]="isMissingFilter()"
        [chosenItem]="conversation().chosenFilter"
        (selectedItem)="handleFilter($event)"
        (dropdownOpen)="handleDropdownOpen($event)"
    />

    @if (isDisplaySuggestion()) {
        <oiv-viewer-prompt (selectPrompt)="handlePromptSelected($event)" [prompts]="prompts()" />
    }

    @if (messages().length > 0) {
        @for (message of messages(); track message.id) {
            <div class="mobi-ui-margin-b-xl">
                @if (message.role === 'user') {
                    <!-- Displaying the timeline for each new Question -->
                    <div class="mobi-ui-padding-y-lg oiv-info-color">
                        <div class="mobi-ui-text-center">
                            @if ($index !== 0) {
                                <oiv-viewer-separator
                                    [dateTime]="message.creationTimestamp.toLocaleString(DateTime.TIME_24_SIMPLE)"
                                    [text]="'oiv.aiSearch.followUpQuestionSeparator' | translate"
                                    [isBold]="false"
                                />
                            }
                        </div>
                    </div>
                    <oiv-user-message [message]="message"></oiv-user-message>
                } @else {
                    <oiv-assistant-message
                        [message]="renderMessageReference(message)"
                        (feedbackSent)="handleSendFeedback($event)"
                        [categories]="feedbackCategories()"
                        [references]="handleReferences(message)"
                    ></oiv-assistant-message>
                }
            </div>
        }
    }

    @if (isFeedbackSent()) {
        <mobi-ui-messages-major>
            <mobi-ui-message-item-major
                [level]="getFeedbackData().level"
                [messageCloseTimeout]="MESSAGE_CLOSE_TIMEOUT"
                (messageClose)="handleMessageClose()"
            >
                {{ 'oiv.feedback.send.success' | translate }}
            </mobi-ui-message-item-major>
        </mobi-ui-messages-major>
    }
</div>
