<div class="mobi-ui-wrapper-content">
    <div class="mobi-ui-container-overflow">
        <div class="mobi-ui-container">
            <div class="mobi-ui-grid mobi-ui-centered mobi-ui-padding-sm mobi-ui-padding-t-xxxl">
                <div class="mobi-ui-col-1/1 md:mobi-ui-col-5/8 mobi-ui-padding-t-xl">
                    <h1
                        class="mobi-ui-margin-sm mobi-ui-margin-t-none mobi-ui-padding-none mobi-ui-font-size-300 sm:mobi-ui-font-size-600"
                    >
                        {{ 'oiv.no-permission.title' | translate }}
                    </h1>
                </div>
            </div>
        </div>
    </div>
</div>
