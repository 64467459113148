import { patchState, signalStoreFeature, type, withComputed, withMethods } from '@ngrx/signals';

import {
    addEntity,
    entityConfig,
    removeAllEntities,
    removeEntity,
    updateEntity,
    withEntities,
} from '@ngrx/signals/entities';

import { Language } from '@mobi/rwc-utils-ng-jslib';

import { Conversation, Message } from '../model/message.model';

const conversationConfig = entityConfig({
    entity: type<Conversation>(),
    collection: '_conversation',
});

export function withConversations<_>() {
    return signalStoreFeature(
        {
            state: type<{ textReference: string; language: Language; isIndexAvailable: boolean }>(),
        },
        withEntities(conversationConfig),
        withComputed(({ _conversationEntities }) => ({
            conversations: _conversationEntities,
        })),
        withMethods(store => ({
            _addTmpConversation(conversation: Conversation) {
                patchState(store, addEntity(conversation, conversationConfig));
            },
            updateConversation(conversation: Conversation) {
                patchState(
                    store,
                    updateEntity(
                        {
                            id: conversation.id,
                            changes: conversation,
                        },
                        conversationConfig,
                    ),
                );
            },
            updateMessages(conversationId: string, messages: Message[]) {
                patchState(
                    store,
                    updateEntity(
                        {
                            id: conversationId,
                            changes: {
                                messages: messages,
                            },
                        },
                        conversationConfig,
                    ),
                );
            },
            _removeConversation(conversationId: string) {
                patchState(store, removeEntity(conversationId, conversationConfig));
            },
            _clearAll() {
                patchState(store, removeAllEntities(conversationConfig));
            },
        })),
    );
}
