import { Component, Renderer2, ChangeDetectionStrategy, input, OnChanges, SimpleChanges, inject } from '@angular/core';
import { PushPipe } from '@ngrx/component';

@Component({
    selector: 'oiv-viewer-overlay',
    standalone: true,
    templateUrl: './overlay.component.html',
    styleUrl: './overlay.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [PushPipe],
})
export class OverlayComponent implements OnChanges {
    #renderer = inject(Renderer2);

    isVisible = input<boolean>(false);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['isVisible'].currentValue) {
            this.#renderer.addClass(document.body, 'overlay-open');
        } else {
            this.#renderer.removeClass(document.body, 'overlay-open');
        }
    }
}
