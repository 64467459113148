import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MobiUiIconComponent, MobiUiIconsRegistry } from '@mobi/rwc-ui-components-ng-jslib';
import { TranslationModule } from '@mobi/rwc-utils-ng-jslib';
import { mobiIconApplicationWindow, mobiIconFace, mobiIconGoToLink } from '@mobi/rwc-utils-icons-jslib';

@Component({
    selector: 'oiv-viewer-more-information',
    standalone: true,
    imports: [CommonModule, MobiUiIconComponent, TranslationModule],
    templateUrl: './more-information.component.html',
    styleUrl: './more-information.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreInformationComponent {
    items = [
        {
            text: 'oiv.not-found.customer-portal',
            link: 'oiv.not-found.customer-portal.link',
            icon: 'application_window',
        },
        { text: 'oiv.not-found.contact', link: 'oiv.not-found.contact.link', icon: 'face' },
    ];

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconGoToLink, mobiIconFace, mobiIconApplicationWindow]);
    }
}
