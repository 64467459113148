import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { CommonModule } from '@angular/common';

import {
    MobiUiIconComponent,
    MobiUiIllustrationComponent,
    MobiUiIllustrationsRegistry,
} from '@mobi/rwc-ui-components-ng-jslib';
import {
    mobiIllustrationBasicDesktopFileFolder,
    mobiIllustrationBasicHomeSigninLaptop,
    mobiIllustrationBasicPersonHomeoffice,
} from '@mobi/rwc-utils-illustrations-jslib';
import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { B2E } from '@mobi/oiv-viewer-utils-ng-jslib';

import { StackedContainerComponent } from '../../share/components/stacked-container/stacked-container.component';
import { MoreInformationComponent } from '../../share/components/more-information/more-information.component';

import { AiFacade } from '../../oiv-search/ai-search/data/facade/ai-facade';
import { AiStore } from '../../oiv-search/ai-search/data/store/ai.store';

@Component({
    standalone: true,
    selector: 'oiv-viewer-not-found-page',
    templateUrl: './not-found-page.component.html',
    styleUrls: ['./not-found-page.component.scss'],
    imports: [
        MobiUiIllustrationComponent,
        TranslatePipe,
        MobiUiIconComponent,
        StackedContainerComponent,
        CommonModule,
        MoreInformationComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AiFacade, AiStore],
})
export class NotFoundPageComponent implements OnInit {
    #route = inject(ActivatedRoute);
    isB2E = false;
    constructor() {
        inject(MobiUiIllustrationsRegistry).registerIllustrations([
            mobiIllustrationBasicPersonHomeoffice,
            mobiIllustrationBasicDesktopFileFolder,
            mobiIllustrationBasicHomeSigninLaptop,
        ]);
    }

    ngOnInit() {
        this.isB2E = this.#route.snapshot.data['app'] === B2E;
    }
}
