import { computed, inject, Injectable } from '@angular/core';

import { toSignal } from '@angular/core/rxjs-interop';

import { TranslationService } from '@mobi/rwc-utils-ng-jslib';

import { Content, PAGE_URL_PATH, URL_PATH } from '@mobi/oiv-viewer-utils-ng-jslib';

import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { filterDefined } from '../../../util/filter-defined';
import { SidenavItem } from '../model/sidenav';

import { McmInformationService } from '../../core/data/service/mcm-information.service';
import { OivViewerFacade } from '../../core/data/facade/oiv-viewer-facade';

@Injectable({ providedIn: 'root' })
export class NavigationFacade {
    #oivViewerFacade = inject(OivViewerFacade);
    #translationService = inject(TranslationService);
    #mcmService = inject(McmInformationService);

    get sidenav() {
        const title = toSignal(this.#translationService.translate('oiv.homepage.title.content'), {
            initialValue: 'Inhalt',
        });
        const chapters = this.#oivViewerFacade.avbChapters;
        return computed(() => this.#buildSidenav(chapters(), title()));
    }

    downloadPdf(textReference: string, language: string) {
        return this.#mcmService.downloadPdf(textReference, language).pipe(filterDefined);
    }

    #buildSidenav(chapters: Content[], title: string): SidenavItem[] {
        return [
            {
                routerLink: PAGE_URL_PATH.HOMEPAGE,
                icon: 'book_open',
                title,
                children: chapters.map(chapter => this.#buildSidenavItem(chapter)),
            },
        ];
    }

    #buildSidenavItem(chapter: Content): SidenavItem {
        if (chapter.type === OIV_TYPE.CHAPTER) {
            const topics: Content[] = [];
            const sidenavItem: SidenavItem = {
                routerLink: '',
                title: '',
                chapterId: chapter.eId,
                topicId: '',
                children: [],
            };
            (chapter.content as Array<Content>).forEach((content: Content) => {
                // Get the title of the chapter
                if (content.type === OIV_TYPE.CHAPTER_TITLE) {
                    sidenavItem.title = content.content as string;
                }

                // Get topics of the chapter
                if (content.type === OIV_TYPE.TOPIC && content.content instanceof Array && content.content.length > 0) {
                    const topicTitle = (content.content as Array<Content>).find(
                        (content: Content) => content.type === OIV_TYPE.TOPIC_TITLE,
                    );
                    if (topicTitle) {
                        topics.push(content);
                    }
                }
            });
            if (topics.length === 0) {
                sidenavItem.routerLink = `${URL_PATH.CHAPTERS}/${sidenavItem.chapterId}`;
            } else {
                sidenavItem.routerLink = `${URL_PATH.CHAPTERS}/${sidenavItem.chapterId}/${URL_PATH.TOPICS}`;
                sidenavItem.children = topics.map((topic: any) =>
                    this.#buildTopicItem(sidenavItem.chapterId as string, topic),
                );
            }
            return sidenavItem;
        }
        return {
            routerLink: '',
            title: '',
            chapterId: '',
            topicId: '',
            children: [],
        };
    }

    #buildTopicItem(chapterId: string, topic: Content): SidenavItem {
        const topicTitle = (topic.content as Array<Content>).find(
            (content: Content) => content.type === OIV_TYPE.TOPIC_TITLE,
        );
        if (topicTitle) {
            return {
                routerLink: `${URL_PATH.CHAPTERS}/${chapterId}/${URL_PATH.TOPICS}/${topic.eId}`,
                chapterId,
                topicId: topic.eId,
                title: topicTitle?.content as string,
                children: [],
            };
        }
        return {
            routerLink: '',
            title: '',
            chapterId: '',
            topicId: '',
            children: [],
        };
    }
}
