import { Language } from '@mobi/rwc-utils-ng-jslib';
import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

export interface ContentItem {
    eId: string;
    attributeOrder?: string;
    type: OIV_TYPE;
    content: string;
}

export interface Avb {
    language: Language;
    textReference: string;
    urlName: string;
    contentVersion: string;
    assetType?: AssetType;
    censhareId: number;
    productArtUrl?: string;
    xmlContent?: string;
    dateOfAvailability?: string;
    sapPartNumber?: string;
    description?: string;
    productArt?: number;
    documentArt?: number;
    articleCreatedAt?: string;
    articleModifiedAt?: string;
}

export interface Index {
    applicationId: string;
    indexId: string;
    searchOnlyApiKey: string;
    textReference: string;
    language: string;
    urlName: string;
}

export interface NavigationItem {
    urlLink: string;
    title: string;
    orderNumber?: string;
    subItems: NavigationItem[];
}

export interface ObjectBase {
    title: string;
    orderNumber?: string;
    eId: string;
    sections: ObjectBase[];
}
export interface ListItem {
    eId: string;
    sequence?: string;
    content: string | ListItem[];
    type: OIV_TYPE;
}

export enum AssetType {
    ARTICLE_XML = 'ARTICLE_XML',
    APPROVAL_XML = 'APPROVAL_XML',
}

export type AssetPayload = {
    textReference: string;
    language: Language;
    assetType: AssetType;
};

export type AssetInfo = {
    contentVersion: string;
} & AssetPayload;
