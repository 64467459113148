<div id="ai-search" class="ai-search">
    <div class="mobi-ui-padding-t-xs mobi-ui-padding-b-sm">
        <h4 class="mobi-ui-font-tisa mobi-ui-ink-default mobi-ui-padding-b-none mobi-ui-margin-b-xs">
            {{ 'oiv.aiSearch.title' | translate }}
        </h4>
        <!-- Disclaimer -->
        <div class="mobi-ui-card-silent mobi-ui-ink-blue">
            <div class="mobi-ui-text-block-major">
                <mobi-ui-icon-major name="info"></mobi-ui-icon-major>
                <div class="mobi-ui-text-block-content-major">
                    {{ 'oiv.aiSearch.disclaimer' | translate }}
                </div>
            </div>
        </div>
    </div>

    <!--chat zone-->
    <div class="mobi-ui-width-full oiv-chat-zone">
        @if (!isPending()) {
            @if (isIndexAvailable()) {
                @for (conversation of conversations(); track conversation.id) {
                    <oiv-viewer-conversation
                        [conversation]="conversation"
                        [isMissingFilter]="isMissingFilter()"
                        (suggestionSelected)="handleSuggestionSelected($event)"
                        (selectedFilterItem)="handleFilterSelected($event)"
                        (filterOpen)="handleFilterOpen($event)"
                    />
                }
            } @else {
                <oiv-viewer-error-message
                    [messageTitle]="'oiv.error.aiSearch.notAvailable.title' | translate"
                    [messageDescription]="'oiv.error.aiSearch.notAvailable.description' | translate"
                />
            }
        } @else {
            <mobi-ui-loading-indicator-major />
        }
    </div>

    @if (isMissingFilter()) {
        <div class="mobi-ui-margin-t-sm">
            <oiv-viewer-error-message
                [messageTitle]="'oiv.error.aiSearch.filterIsMissing.title' | translate"
                [messageDescription]="'oiv.error.aiSearch.filterIsMissing.description' | translate"
            />
        </div>
    }

    <oiv-console
        [isGenerating]="isGenerating()"
        [isIndexAvailable]="isIndexAvailable()"
        [hideCreateConversationBtn]="isDisappearCreateNewConversationFunction()"
        [isFollowUpQuestion]="isFollowUpQuestion()"
        [currentSelectedFilterItem]="currentSelectedFilterItem()"
        (messageSent)="send($event)"
        (cancel)="stopGenerating()"
        (isCreateNewConversation)="createNewConversation()"
    />
</div>
