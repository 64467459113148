import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';

import { RouterLink, RouterLinkActive } from '@angular/router';

import {
    MobiUiIconComponent,
    MobiUiIconsRegistry,
    MobiUiSidenavComponent,
    MobiUiSidenavItemLevel1Component,
    MobiUiSidenavItemLevel2Component,
    MobiUiSidenavItemLevel3Component,
} from '@mobi/rwc-ui-components-ng-jslib';
import { mobiIconBookOpen } from '@mobi/rwc-utils-icons-jslib';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { TealiumTrackLinkOnsiteDirective } from '@mobi/tea-tagmanager-ng-jslib';

import { SidenavItem } from '../../model/sidenav';
import { GiveMeMoreComponent } from '../../feature/give-me-more/give-me-more.component';

@Component({
    standalone: true,
    selector: 'oiv-viewer-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    imports: [
        MobiUiIconComponent,
        MobiUiSidenavComponent,
        MobiUiSidenavItemLevel1Component,
        MobiUiSidenavItemLevel2Component,
        MobiUiSidenavItemLevel3Component,
        RouterLink,
        RouterLinkActive,
        TranslatePipe,
        GiveMeMoreComponent,
        TealiumTrackLinkOnsiteDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent {
    sidenavItems = input<SidenavItem[]>([]);
    navigationClick = output();

    #mobiIcon = inject(MobiUiIconsRegistry).registerIcons([mobiIconBookOpen]);

    hasChildren(item: SidenavItem): boolean {
        return item && item.children && item.children.length > 0;
    }

    closeOverlay() {
        this.navigationClick.emit();
    }
}
