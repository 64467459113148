import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    MobiUiFormFieldComponent,
    MobiUiIconComponent,
    MobiUiIconsRegistry,
    MobiUiInputComponent,
} from '@mobi/rwc-ui-components-ng-jslib';
import { TranslationModule } from '@mobi/rwc-utils-ng-jslib';
import { mobiIconGoToLink } from '@mobi/rwc-utils-icons-jslib';

@Component({
    selector: 'oiv-viewer-stacked-container',
    standalone: true,
    imports: [CommonModule, MobiUiFormFieldComponent, MobiUiInputComponent, TranslationModule, MobiUiIconComponent],
    templateUrl: './stacked-container.component.html',
    styleUrl: './stacked-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StackedContainerComponent {
    @Input() title = '';
    @Input() link = '';

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconGoToLink]);
    }

    openRedirect() {
        if (this.link) window.open(this.link, '_self');
    }
}
