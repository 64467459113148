<div class="mobi-ui-animation-appear-init mobi-ui-animation-appear-render mobi-ui-card-silent mobi-ui-relative">
    <div class="gptlib-message-bubble mobi-ui-slot-major mobi-ui-ink-blue is-top-right-inner">
        <mobi-ui-icon-major name="face_woman_b" size="md"></mobi-ui-icon-major>
    </div>
    <ng-container>
        <lib-gpt-message class="mobi-ui-padding-b-xxs mobi-ui-margin-t-md" [message]="message"></lib-gpt-message>
        <div class="mobi-ui-separator-minor mobi-ui-margin-b-none"></div>
        <div class="oiv-message-actions mobi-ui-padding-xxs mobi-ui-flex">
            <mobi-ui-icon-major
                size="md"
                name="copy"
                (click)="copyToClipboard()"
                [mobiUiPopover]="copyConfirmation"
            ></mobi-ui-icon-major>
        </div>
    </ng-container>
</div>

<ng-template #copyConfirmation>
    <mobi-ui-popover-major>
        <span [innerHTML]="'oiv.message.copy' | translate"></span>
    </mobi-ui-popover-major>
</ng-template>
