import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MCM_BASE_URL } from '../../../../util/constant/common';

@Injectable({
    providedIn: 'root',
})
export class McmInformationService {
    #http = inject(HttpClient);

    #baseUrl = MCM_BASE_URL;

    downloadPdf(textReference: string, language: string) {
        const url = `${this.#baseUrl}/avb/pdf?language=${language}&textReference=${textReference}`;
        return this.#http.get(url, {
            responseType: 'blob',
        });
    }
}
