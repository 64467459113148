import { inject, Injectable } from '@angular/core';
import { takeWhile } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { Language } from '@mobi/rwc-utils-ng-jslib';

import { delayEmissions } from '@mobi/gpt-chat-ng-jslib';

import { OIV_QA_BASE_URL } from '../../../../util/constant/common';
import { MessageRole } from '../model/message.model';

@Injectable({
    providedIn: 'root',
})
export class QuestionAndAnswerService {
    #http = inject(HttpClient);

    send(messages: MessageRequestDTO) {
        const url = `${OIV_QA_BASE_URL}/chat_with_avb`;
        return this.#http.post<MessageResponseDTO>(url, messages).pipe(
            delayEmissions(60),
            takeWhile(messageAnswer => !messageAnswer.content, true),
        );
    }
}

export interface MessageRequestDTO {
    conversation_id: string;
    fdt_dokumentArt: string;
    language: Language;
    fdt_produktArt: string;
    text_reference: string;
    messages: MessageDTO[];
    filter?: string;
    asset_type: string;
    content_version: string;
}

export interface MessageDTO {
    content: string;
    role: MessageRole;
}

export interface MessageResponseDTO {
    content: string;
    conversation_id: string;
    references: {
        eID: string;
    }[];
}
