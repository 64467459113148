<div class="mobi-ui-margin-b-xl">
    <form [formGroup]="searchForm">
        <mobi-ui-form-field-major>
            <mobi-ui-input-major
                [icon]="searchForm.get('query')?.value ? 'the_x' : 'search'"
                (keydown.enter)="$event.preventDefault()"
                (iconClick)="clear()"
                formControlName="query"
                [placeholder]="'oiv.placeholder.search' | translate"
                aria-label="{{ 'oiv.placeholder.search' | translate }}"
            ></mobi-ui-input-major>
            <mobi-ui-form-message-major *mobiUiErrorMessage="'required'">
                {{ 'oiv.search.error.message' | translate }}
            </mobi-ui-form-message-major>
        </mobi-ui-form-field-major>
    </form>
</div>
