export function isHTMLElementInViewport(el: HTMLElement | null) {
    if (!el) {
        return false;
    }
    const item = el.getBoundingClientRect();
    return (
        item.top >= 0 &&
        item.left >= 0 &&
        Math.floor(item.bottom) <= (window.innerHeight || document.documentElement.clientHeight) &&
        Math.floor(item.right) <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
