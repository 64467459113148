import { ChangeDetectionStrategy, Component, EventEmitter, input, Output, signal } from '@angular/core';

import { CommonModule } from '@angular/common';

import { LetDirective } from '@ngrx/component';

import { MobiUiPaginatorComponent, MobiUiPaginatorPageEvent } from '@mobi/rwc-ui-components-ng-jslib';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import { SearchResultItem, SearchResultItemComponent } from '../search-result-item/search-result-item.component';

import { SEARCH_PAGE_SIZE } from '../../../../util/constant/common';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'oiv-viewer-search-result-list',
    standalone: true,
    imports: [CommonModule, SearchResultItemComponent, MobiUiPaginatorComponent, LetDirective, TranslatePipe],
    templateUrl: './search-result-list.component.html',
    styleUrls: [
        './search-result-list.component.scss',
        '../../../../../../../../apps/oiv-viewer-b2e-rwc/src/styles.scss',
    ],
})
export class SearchResultListComponent {
    @Output() changePage: EventEmitter<MobiUiPaginatorPageEvent> = new EventEmitter<MobiUiPaginatorPageEvent>();
    keySearch = input<string>('');
    allResultItemsPerPage = input<SearchResultItem[]>([]);
    numberHits = input<number>(0);
    currentPage = input<number>(0);

    pageSize = signal(SEARCH_PAGE_SIZE);

    onChangePage(pageEvent: MobiUiPaginatorPageEvent): void {
        this.changePage.emit(pageEvent);
    }
}
