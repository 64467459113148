@if (isLoadingIndex()) {
    <mobi-ui-loading-indicator-major></mobi-ui-loading-indicator-major>
} @else {
    <div class="mobi-ui-padding-y-md">
        <h4 class="mobi-ui-font-tisa mobi-ui-ink-default">{{ 'oiv.indexSearch.title' | translate }}</h4>
    </div>
    <oiv-viewer-search-box
        [keySearch]="keySearch()"
        [isDisabled]="loadingStatus() !== LoadingState.READY"
        (searchTextChange)="changeSearchParam($event)"
    ></oiv-viewer-search-box>
    @if (isLoadingSearch()) {
        <mobi-ui-loading-indicator-major></mobi-ui-loading-indicator-major>
    } @else if (loadingStatus() !== LoadingState.ERROR) {
        <oiv-viewer-search-result-list
            (changePage)="changePageParam($event)"
            [allResultItemsPerPage]="allResultItems()"
            [keySearch]="keySearch()"
            [currentPage]="currentPage()"
            [numberHits]="numberHits()"
        ></oiv-viewer-search-result-list>
    } @else if (loadingStatus() === LoadingState.ERROR) {
        <div class="mobi-ui-card-silent mobi-ui-ink-pink">
            <div class="mobi-ui-text-block-major">
                <mobi-ui-icon-major name="error"></mobi-ui-icon-major>
                <div class="mobi-ui-text-block-content-major">
                    <span class="mobi-ui-text-block-title-major">{{ 'oiv.indexSearch.error.title' | translate }}</span>
                    {{ 'oiv.indexSearch.error.text' | translate }}
                </div>
            </div>
        </div>
    }
}
