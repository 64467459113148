<div class="mobi-ui-padding-sm mobi-ui-margin-none pointer" (click)="openRedirect()">
    <div class="mobi-ui-container-stacked mobi-ui-aspect-ratio-2/3 is-small mobi-ui-padding-sm">
        <div
            class="front mobi-ui-container-stacked-item mobi-ui-card-signature-minor mobi-ui-box-shadow-minor mobi-ui-padding-none"
        >
            <div class="mobi-ui-grid mobi-ui-padding-x-md">
                <div class="mobi-ui-col-1/1 mobi-ui-text-center">
                    <h1
                        class="mobi-ui-margin-none mobi-ui-margin-t-xl mobi-ui-margin-b-md mobi-ui-font-size-200 sm:mobi-ui-font-size-300 mobi-ui-padding-x-md"
                    >
                        {{ title }}
                    </h1>
                    <ng-content />
                </div>
            </div>
        </div>
        <div class="mobi-ui-container-stacked-item mobi-ui-card-signature-major"></div>
        <div class="mobi-ui-container-stacked-slot-bottom">
            <span
                class="mobi-ui-font-size-100 sm:mobi-ui-font-size-200 mobi-ui-font-tisa mobi-ui-space-between mobi-ui-width-full mobi-ui-contrast-color"
            >
                {{ 'oiv.not-found.continue' | translate }}
                <mobi-ui-icon-major name="go_to_link"></mobi-ui-icon-major>
            </span>
        </div>
    </div>
</div>
