import { ChangeDetectionStrategy, Component, computed, effect, inject, OnInit, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DateTime } from 'luxon';

import { ReactiveFormsModule } from '@angular/forms';

import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

import {
    MobiUiDatePipe,
    MobiUiDropdownComponent,
    MobiUiDropdownOptionComponent,
    MobiUiFormFieldComponent,
    MobiUiIconComponent,
    MobiUiIconsRegistry,
    MobiUiInputComponent,
    MobiUiLoadingIndicatorComponent,
    MobiUiMessageItemComponent,
    MobiUiMessagesComponent,
} from '@mobi/rwc-ui-components-ng-jslib';

import { mobiIconEdit, mobiIconInfo } from '@mobi/rwc-utils-icons-jslib';

import { OivCoreSharedFacade } from '@mobi/oiv-viewer-utils-ng-jslib';

import { ConsoleComponent } from '../ui/console/console.component';
import { UserMessageComponent } from '../ui/user-message/user-message.component';
import { AssistantMessageComponent } from '../ui/assistant-message/assistant-message.component';

import { AiFacade } from '../data/facade/ai-facade';
import { AiFilterComponent } from '../ui/filter/ai-filter.component';
import { ErrorMessageComponent } from '../ui/error-message/error-message.component';
import { SeparatorComponent } from '../ui/separator/separator.component';

import { OivViewerFacade } from '../../../oiv-viewer/core/data/facade/oiv-viewer-facade';
import { AssetInfo } from '../../../share/model/atomic-model';

import { ConversationComponent } from './conversation/conversation.component';

type ScrollToPosition = 'top' | 'bottom';

@Component({
    selector: 'oiv-viewer-ai-search',
    standalone: true,
    imports: [
        CommonModule,
        ConsoleComponent,
        UserMessageComponent,
        AssistantMessageComponent,
        TranslatePipe,
        MobiUiInputComponent,
        MobiUiMessageItemComponent,
        MobiUiMessagesComponent,
        MobiUiDatePipe,
        MobiUiIconComponent,
        MobiUiLoadingIndicatorComponent,
        MobiUiDropdownComponent,
        MobiUiDropdownOptionComponent,
        ReactiveFormsModule,
        MobiUiFormFieldComponent,
        AiFilterComponent,
        ErrorMessageComponent,
        SeparatorComponent,
        ConversationComponent,
    ],
    templateUrl: './ai-search.component.html',
    styleUrls: ['./ai-search.component.scss', '../../../../../../../apps/oiv-viewer-b2e-rwc/src/styles.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiSearchComponent implements OnInit {
    #aiFacade = inject(AiFacade);
    #oivViewerFacade = inject(OivViewerFacade);
    #oivCoreSharedFacade = inject(OivCoreSharedFacade);

    consoleComponent = viewChild.required(ConsoleComponent);
    protected readonly DateTime = DateTime;

    #avbMetaData = this.#oivViewerFacade.avbMetadata;
    isGenerating = this.#aiFacade.isGenerating;
    language = this.#oivCoreSharedFacade.currentLanguage;

    isIndexAvailable = this.#aiFacade.isIndexAvailable;
    isPending = this.#aiFacade.isPending;
    isFollowUpQuestion = this.#aiFacade.isFollowUpQuestion;
    conversations = this.#aiFacade.conversations;
    isDisappearCreateNewConversationFunction = this.#aiFacade.isDisappearCreateNewConversationFunction;
    currentSelectedFilterItem = this.#aiFacade.currentSelectedFilterItem;

    #isSentMessageWithoutFilter = signal(false);
    isMissingFilter = computed(() => {
        return this.#aiFacade.currentSelectedFilterItem() === '' && this.#isSentMessageWithoutFilter();
    });

    #currentConversationId = this.#aiFacade.currentConversationId;
    #currentLanguage = this.#oivCoreSharedFacade.currentLanguage();
    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconInfo, mobiIconEdit]);
        this.#onConservationsEffect();
        this.#onIndexAvailableEffect();

        // TODO: This is a workaround to fix the issue of the language not being set when the component is initialized
        effect(
            () => {
                if (this.language() && this.#avbMetaData() && this.#avbMetaData().language !== this.#currentLanguage) {
                    const assetInfo = {
                        language: this.#avbMetaData().language,
                        textReference: this.#avbMetaData().textReference,
                        assetType: this.#avbMetaData().assetType,
                        contentVersion: this.#avbMetaData().contentVersion,
                    } as AssetInfo;
                    this.#aiFacade.initializeAiStore(assetInfo);
                    this.#currentLanguage = this.language();
                    // refresh invalid filter
                    this.#isSentMessageWithoutFilter.set(false);
                }
            },
            { allowSignalWrites: true },
        );
    }

    ngOnInit(): void {
        const assetInfo = {
            language: this.#avbMetaData().language,
            textReference: this.#avbMetaData().textReference,
            assetType: this.#avbMetaData().assetType,
            contentVersion: this.#avbMetaData().contentVersion,
        } as AssetInfo;
        this.#aiFacade.initializeAiStore(assetInfo);
    }

    handleFilterSelected($event: string) {
        this.#aiFacade.currentSelectedFilterItem = $event;
    }

    send(submitMessage: string) {
        if (this.currentSelectedFilterItem() === '' || submitMessage === '') {
            this.#isSentMessageWithoutFilter.set(true);
            this.scrollTo('bottom');
            return;
        }
        this.#isSentMessageWithoutFilter.set(false);
        this.#aiFacade.sendMessageSubmissionRequested(
            this.#currentConversationId(),
            this.#avbMetaData().productArt || 0,
            this.#avbMetaData().documentArt || 0,
            submitMessage,
            this.currentSelectedFilterItem(),
            this.#avbMetaData().assetType || '',
            this.#avbMetaData().contentVersion || '',
        );
    }

    stopGenerating() {
        this.#aiFacade.stopGeneratingRequested();
    }

    scrollTo(position: ScrollToPosition): void {
        if (position === 'top') {
            document.defaultView?.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else {
            setTimeout(() => {
                this.#scrollToBottom();
            }, 100);
        }
    }

    createNewConversation() {
        this.#aiFacade.addTmpConversation();
    }

    handleSuggestionSelected(suggestion: string) {
        this.consoleComponent().textarea?.writeValue(suggestion);
        this.consoleComponent().sendMessage();
    }

    handleFilterOpen(isOpened: boolean) {
        // we auto scroll to bottom when the filter is opened
        // and on the second conversation
        if (isOpened && this.conversations().length > 1) {
            this.#scrollToBottom();
        }
    }

    #scrollToBottom() {
        let div = document.querySelector('.overlay');
        if (!this.#oivCoreSharedFacade.isMobile()) {
            div = document.querySelector('.oiv-sticky');
        }
        if (!div) {
            return;
        }
        div.scrollTo({ top: div.scrollHeight + 150, behavior: 'smooth' });
    }

    #onIndexAvailableEffect() {
        effect(
            () => {
                if (this.isIndexAvailable()) {
                    // load prompts
                    this.#aiFacade.loadPrompts();
                    // create tmp conversation
                    this.#aiFacade.addTmpConversation();
                } else {
                    this.#aiFacade.clearAll();
                }
            },
            { allowSignalWrites: true },
        );
    }

    #onConservationsEffect() {
        effect(() => {
            if (this.conversations().length != 0 && this.conversations()[0].messages.length != 0) {
                this.scrollTo('bottom');
            }
        });
    }
}
